// extracted by mini-css-extract-plugin
export var container = "footer-module--container--YpA6j";
export var mobileContainer = "footer-module--mobileContainer--Jjz0J";
export var content = "footer-module--content--V+RSI";
export var logo = "footer-module--logo--Yt0b+";
export var arrowDown = "footer-module--arrowDown--QOe0g";
export var arrowUp = "footer-module--arrowUp--fPBKD";
export var footerLinksContainer = "footer-module--footerLinksContainer--R3tMw";
export var footerLinksContainerCollapsed = "footer-module--footerLinksContainerCollapsed--lpeag";
export var columnLinksContainer = "footer-module--columnLinksContainer--NJJII";
export var columnLinksContainerCollapsed = "footer-module--columnLinksContainerCollapsed--OHiKr";
export var rowLinksContainer = "footer-module--rowLinksContainer--5-bKu";
export var rowLinksContainerCollapsed = "footer-module--rowLinksContainerCollapsed--AwJKk";
export var linkContainer = "footer-module--linkContainer--3zsML";
export var rowLinkContainer = "footer-module--rowLinkContainer--ssz+3";
export var link = "footer-module--link--Jn+2x";
export var linkRow = "footer-module--linkRow--KxgNk";
export var linksSeparator = "footer-module--linksSeparator--8KCm+";