import React, {useEffect} from "react";
import * as styles from "./header.module.scss";
import logo from "../../../assets/splash.png";

const Header = () => {
    useEffect(() => {
        const script = document.createElement("script")
        const head = document.getElementsByTagName("head")
    
        script.type = "text/javascript"
        script.text =
          `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}\n` +
          `else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}\n` +
          `var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}\n` +
          `if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){\n` +
          `var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("2127e597-3d3f-4aa2-9727-c8126f86298c")})();`
    
        head[0].prepend(script)
      }, [])

    return (
      <header
        id="header"
        data-datocms-noindex={true}
        role="banner"
      >
        <script
        type="didomi/javascript"
        data-vendor="c:googleana-4TXnJigR"
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            ga('create', '295319720', 'auto');
            ga('send', 'pageview');`,
        }}
      />
      <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PN68QWGV');`,
      }}
    />
        </header>

    )

}

export const enableGoogleTagManagerHead = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PN68QWGV');`,
      }}
    />
  )
}

export const enableGoogleTagManagerBody = () => {
  const body = document.getElementsByTagName("body")
  const noscript = document.createElement("noscript")
  const iframe = document.createElement("iframe")

  iframe.src = `https://www.googletagmanager.com/ns.html?id=GTM-PN68QWGV`
  iframe.height = "0"
  iframe.width = "0"
  iframe.setAttribute("style", "display:none;visibility:hidden")

  noscript.appendChild(iframe)

  body[0].prepend(noscript)
}

export default Header;