declare global {
  interface Window {
    dataLayer: any[]
  }
}

export class InteractionEvent {
  eventObject: Object

  constructor(event: string, category: string, action: string, label: string) {
    this.eventObject = {
      event: event,
      e_category: category,
      e_action: action,
      e_label: label,
    }
  }

  pushInteractionEvent(dataLater) {
    dataLater.push(this.eventObject)
  }
}

export function pushGtmEvent(eventName, eventCategory, eventAction, eventLabel) {
  let dataLayer = typeof window !== "undefined" && window.dataLayer ? window.dataLayer : []
  const eventObject = new InteractionEvent(eventName, eventCategory, eventAction, eventLabel)
  eventObject.pushInteractionEvent(dataLayer)
}

export class PageEvent {
  eventObject: Object
  constructor(
    event: string,
    page_template: string,
  ) {
    this.eventObject = {
      event: event,
      page_template: page_template,

    }
  }
  pushPageEvent(dataLater) {
    dataLater.push(this.eventObject)
  }
}

export function pushGtmPageEvent(
  event,
  page_template,
) {
  let dataLayer = typeof window !== "undefined" && window.dataLayer ? window.dataLayer : []
  const eventObject = new PageEvent(
    event,
    page_template,
  )
  eventObject.pushPageEvent(dataLayer)
}
