import React, {Fragment, useState} from 'react';
import * as styles from './footer.module.scss';
import logo from '../../../assets/images/logo_white.png';
import {graphql} from "gatsby";
import {FooterFieldsFragment} from "../../../../graphql-types";
import useWindowWidth from "../../../hooks/use-window-width";

type RenderProps = {
    data: FooterFieldsFragment
}

const Footer:React.FC<RenderProps> = ({data}) => {

    const [footerOpened, setFooterOpened] = useState(true);
    const isMobile = useWindowWidth() <= 1200;

    return (
        <section className={isMobile ? styles.mobileContainer : styles.container}>
            <div className={styles.content}>
                <a aria-label="Go to home page" href="/">
                    <img className={styles.logo} src={logo} alt="Keolis logo"/>
                </a>
            </div>
            <div className={footerOpened ? styles.footerLinksContainer : styles.footerLinksContainerCollapsed}>
                <ul className={footerOpened ? styles.columnLinksContainer : styles.columnLinksContainerCollapsed}>
                    {data.columnLinks.map(link => {
                        return (
                           <li key={link.id} className={styles.linkContainer}>
                               <a
                                   aria-label={`Link go to ${link.title}`}
                                   href={link.isInternalLink ?
                                       (link.slug && link.slug[0] === '/' ? link.slug : `/${link.slug}`) :
                                       link.slug
                                   }
                                   className={styles.link}
                               >
                                   {link.title}
                               </a>
                           </li>
                        )
                    })}
                </ul>
                <ul className={footerOpened ? styles.rowLinksContainer : styles.rowLinksContainerCollapsed}>
                    {data.rowLinks.map((link, index) => {
                        return (
                            <Fragment key={link.id} >
                                <li className={styles.rowLinkContainer}>
                                    <a
                                        aria-label={`Link go to ${link.title}`}
                                        href={link.isInternalLink ?
                                            (link.slug && link.slug[0] === '/' ? link.slug : `/${link.slug}`) :
                                            link.slug
                                        }
                                        className={styles.linkRow}
                                    >
                                        {link.title}
                                    </a>
                                </li>
                                <div className={styles.linksSeparator}>|</div>
     
                                {index === data.rowLinks.length - 1 && (
                                    <li key={link.id} className={styles.rowLinkContainer}>
                                        <a
                                            aria-label={`Consent choices`}
                                            href="javascript:Didomi.preferences.show()"
                                            className={styles.linkRow}
                                        >
                                          Préférences cookies
                                        </a>
                                    </li>
                                )}

                            </Fragment>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}

export const fragment = graphql`
    fragment FooterFields on DatoCmsFooter {
        id
        columnLinks {
            id
            title
            slug
            isInternalLink
        }
        rowLinks {
            id
            title
            slug
            isInternalLink
        }
    }
`

export default Footer;