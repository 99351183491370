import React, {useState} from "react";
import * as styles from "./sticky-buttons.module.scss";
import {graphql} from "gatsby";
import {displayImage} from "../../global/functions/functions";
import {ChatbotFieldsFragment, StickyButtonsFieldsFragment} from "../../../graphql-types";
import {useHover} from "../../hooks/useHover";
import InfoContactButton from "./info-contact/info-contact-button";
import Assistant from "./assistant/assistant";
import axios from "axios";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: StickyButtonsFieldsFragment,
    chatbotData: ChatbotFieldsFragment
}

const StickyButtons:React.FC<ReactProps> = ({data, chatbotData}) => {


    const isMobile = useWindowWidth() <= 1200;

    const apiAddress = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
        "https://q-ksa-mad-common-euw-apim.azure-api.net/K111/tourism" :
        "https://apim.keolis.com/K111/tourism"
    const subscriptionKey = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
        "4a48f051813c41ed96503c947431e1e1" :
        "ea85bdca5b484f45a70d5c5637e3267f"
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    const [displayAssistant, setDisplayAssistant] = useState(false);
    const [subsidiaries, setSubsidiaries] = useState(null);
    const [isActive, setIsActive] = useState(true);

    const getSubsidiaries = async () => {
        if(isActive) {
            await axios.get(`${apiAddress}/subsidiaries/info`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': subscriptionKey
                }
            }).then(res => {
                setSubsidiaries(res.data)
                setIsActive(false)
            }).catch(err => {
                setIsActive(false)
                return
            })
        }
    }

    getSubsidiaries()


    // à remettre dans le mobile aussi
    return (
            <section className={styles.containerClosed}>
            <InfoContactButton data={data} displayAssistant={displayAssistant} setDisplayAssistant={handleDisplayAssistant} />
            <div className={displayAssistant ? styles.displayAssistant : styles.hideAssistant}>
                {subsidiaries && (
                    <Assistant chatbotData={chatbotData} setDisplayAssistant={handleClose} subsidiaries={subsidiaries} />
                )}
            </div>
        </section>
    )

    function handleClose() {
        setDisplayAssistant(false)
    }

    function handleDisplayAssistant() {
        setDisplayAssistant(prevState => !prevState)
    }

}

export const fragment = graphql`
    fragment StickyButtonsFields on DatoCmsWebStickyButton {
        devisGratuitTitle
        devisGratuitIcon {
            alt
            title
            format
            url
            gatsbyImageData(layout: FIXED)
        }
        devisGratuitIconHover {
            alt
            title
            format
            url
            gatsbyImageData(layout: FIXED)
        }
        infoAndContactTitle
        infoAndContactIcon {
            alt
            title
            format
            url
            gatsbyImageData(layout: FIXED)
        }
        infoAndContactNewIcon {
            alt
            title
            format
            url
            gatsbyImageData(layout: FIXED)
        }
        infoAndContactIconHover {
            alt
            title
            format
            url
            gatsbyImageData(layout: FIXED)
        }
    }
    
    fragment ChatbotFields on DatoCmsChatbot {
        aboutLastTrip
        faq
        contactUs
        iWantToConsultFaq
        knowOurServices
        mostFrequentlyAskedQuestions
        welcomeMessage
        faqQuestionsList {
            label
            question
            answer
        }
        doYouResearchAnotherService
        whatDoYouNeed
        iWantToContactKeolis
        obtenirUnRenseignement
        demanderUnDevis
        renseignement
        renseignementResponse
        keolisPresDeChezVous
        whatWasTheDateOfYourTrip
        whatWasYourDestination
        whatWasTheGroupName
        whichSubsidiaryDidYouTravelWith
        whatIsYourEmailAddress
        thanksForTravellingWithUs
        tellUsYourExperience
        shareLastTripExperience
        authorizeKeolisToUseYourDatas
        doYouWantToSendYourDemand
        thanksForYourFeedback
        backgroundImage {
            alt
            title
            format
            url
            gatsbyImageData(layout: FIXED)
        }
    }
`


export default StickyButtons;