import React from "react";
import * as styles from "./info-contact-button.module.scss";
import {displayImage} from "../../../global/functions/functions";
import {useHover} from "../../../hooks/useHover";

type ReactProps = {
    data: any,
    displayAssistant: any,
    setDisplayAssistant: any
}

const InfoContactButton:React.FC<ReactProps> = ({data, displayAssistant, setDisplayAssistant}) => {

    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    return (
        <div role="button" ref={hoverRef} onClick={() => setDisplayAssistant(!displayAssistant)}>
            {displayImage(data.infoAndContactNewIcon, styles.infoContactIcon)}
        </div>
    )

}

export default InfoContactButton;