import React, {useState} from "react";
import * as styles from './date-picker.module.scss'
import {DatePicker} from "react-responsive-datepicker";
import arrow from '../../../../assets/vectors/arrow.svg';
import moment from 'moment';

type ReactProps = {
    updateContactForm: any,
    displayDestinationInput: any,
    handleChange: any,
    whatWasYourDestination: string
}

const DatePickerForm: React.FC<ReactProps> = ({ updateContactForm, displayDestinationInput, handleChange, whatWasYourDestination }) => {

    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [date, setDate] = useState(moment(new Date()).format("DD/MM/YYYY"))

    return (
        <div className={styles.inputContainer}>
            <form className={styles.dateForm} onSubmit={(e) => {
                e.preventDefault()
                updateContactForm(
                    "Date de voyage",
                    date,
                    whatWasYourDestination,
                    displayDestinationInput()
                )
                handleChange(date)
            }}>
       
                    <input
                        onClick={() => {
                            setOpenDatePicker(true)
                        }}
                        id="tripDate"
                        autoComplete="off"
                        className={styles.emailInput}
                        placeholder={date}
                    />
                    <button aria-label="Submit button" className={styles.submitButton} type="submit">
                        <img src={arrow} className={styles.emailFormArrow} alt="Arrow icon"/>
                    </button>

                {openDatePicker && (
                    <div className={styles.datePickerContainer}>
                        <DatePicker
                            isOpen={true}
                            defaultValue={new Date()}
                            onClose={() => setOpenDatePicker(false)}
                            headerFormat="DD, MM dd"
                            colorScheme={"#005F7D"}
                            onChange={(e) => {
                                setDate(moment(e).format("DD/MM/YYYY"));
                            }}
                        />
                    </div>
                )}
            </form>
        </div>
    )
}

export default DatePickerForm
