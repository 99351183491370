import React, {Fragment, useState} from "react";
import * as styles from "./assistant.module.scss";
import close from '../../../assets/images/close.png';
import chatBoot from '../../../assets/images/chat_boot.png';
import closeWhite from '../../../assets/images/cancel_white.png';
import arrow from '../../../assets/vectors/arrow.svg';
import keolisChatNew from '../../../assets/images/keolis_chat_new.png';
import user from '../../../assets/images/user_icon.png';
import axios from 'axios';
import Autocomplete from "./autocomplete/autocomplete";
import {ChatbotFieldsFragment} from "../../../../graphql-types";
import 'react-responsive-datepicker/dist/index.css';
import DatePickerForm from "./date-picker/date-picker";
import useWindowWidth from "../../../hooks/use-window-width";

type ReactProps = {
    chatbotData: ChatbotFieldsFragment,
    setDisplayAssistant: any,
    subsidiaries: any
}

const Assistant: React.FC<ReactProps> = ({chatbotData, setDisplayAssistant, subsidiaries}) => {
    const isMobile = useWindowWidth() <= 800;

    const apiAddress = "https://apim.keolis.com/K111/tourism"
    const subscriptionKey = "ea85bdca5b484f45a70d5c5637e3267f"

    const chatInitialStatus = () => {
        return [
            {id: "assistantMessage", jsx: assistantMessage(chatbotData.welcomeMessage)},
            {id: "mainOptions", jsx: displayMainOptions()}
        ]
    }
    const [chat, setChat] = useState(chatInitialStatus())
    const [feedbackBody, setFeedbackBody] = useState({
        subsidiaryId: "",
        userEmail: "",
        message: "",
        date: "",
        destination: "",
        groupName: "",
        recordId: 223
    });

   
    return (
        <section className={styles.container}>
            <div className={styles.containerHeader}>
            <div className={styles.containerHeaderLeftSide}>
                <img src={chatBoot} className={styles.chatIcon} alt="Cross icon" />
                <div className={styles.containerHeaderLeftSideText}>Info & Contact</div>
            </div>
            <div className={styles.close}>
                <button aria-label="Férmer assistant" className={styles.resetButton} onClick={setDisplayAssistant}>
                    <img src={close} className={styles.closeIcon} alt="Cross icon" />
                </button>
            </div>
            </div>
            <div style={{ 
                borderBottomRightRadius: '35px',
                borderBottomLeftRadius: '35px',
                backgroundImage: `url(${chatbotData.backgroundImage.url})` 
                }}>
                <div className={styles.assistantContent}>
                    {chat.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                {item.jsx}
                            </Fragment>
                        )
                    })}
                </div>
            </div>
        </section>
    )

    function assistantMessage(message: string): JSX.Element {

        return (
            <div className={styles.assistantMessageContainer}>
                <div className={styles.iconContainer}>
                    <img src={keolisChatNew} className={styles.icon} alt="Croxx icon to reset chat"/>
                </div>
                <div className={styles.bubbleAssistant}>
                    <p className={styles.message}>{message}</p>
                </div>
            </div>
        )
    }

    function clientMessage(message: string): JSX.Element {
        return (
            <div className={styles.clientMessageContainer}>
                <div className={styles.bubbleClient}>
                    <p className={styles.message}>{message}</p>
                    <button aria-label="Reset assistant" className={styles.resetButton} onClick={resetChat}>
                        <img src={closeWhite} className={styles.closeIconClient} alt="Cross icon to reset chat" />
                    </button>
                </div>
                <div className={styles.clientIconContainer}>
                    <img src={user} className={styles.clientIcon} alt="Client icon"/>
                </div>
            </div>
        )
    }

    function displayContactOptions(): JSX.Element {
        return (
            <div className={styles.contactOptionsContainer}>
                <div className={styles.contactOptions}>
                    <a aria-label="Contact option" className={styles.contactOptionButton} href="/devis-location-car">{chatbotData.demanderUnDevis}</a>
                    <button aria-label="Contact option" className={styles.contactOptionButton} onClick={displayRenseignementOption}>{chatbotData.obtenirUnRenseignement}</button>
                </div>
            </div>
        )
    }

    function displayRenseignementOption() {
        setChat(oldChat => {
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(chatbotData.renseignement)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.renseignementResponse)},
                {id: "phoneOption", jsx: displayPhoneButton()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function displayPhoneButton(): JSX.Element {
        return (
            <div className={styles.contactSecondOptionsContainer}>
                <div className={isMobile ? styles.contactOptionsMobile : styles.contactOptions}>
                    <a aria-label="Keolis location" href={"/keolis-en-france"} className={styles.contactOptionButton}>{chatbotData.keolisPresDeChezVous}</a>
                </div>
            </div>
        )
    }

    function displayFeedbackFormConfirmation() {
        setChat(oldChat => {
            oldChat.pop()
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(document.getElementById("experienceDetails").value)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.authorizeKeolisToUseYourDatas)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.doYouWantToSendYourDemand)},
                {id: "sendOrModify", jsx: displaySendOrModifyFeedback()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function displaySendOrModifyFeedback() {
        return (
            <div className={styles.formBouttonsContainer}>
                <form className={styles.emailForm}>
                    <div className={styles.inputContainer} style={{borderBottom: "none", justifyContent: "flex-start"}}>
                        <button aria-label="Submit button" className={styles.basicButton} onClick={(e) => {
                            e.preventDefault()
                            submitFeedbackForm()
                        }}>Envoyer
                        </button>
                        <button aria-label="Submit button" className={styles.basicButton} onClick={(e) => {
                            e.preventDefault()
                            modifyFeedback()
                        }}>Modifier
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    function modifyFeedback() {
        setChat(oldChat => {
            oldChat.pop()
            oldChat.pop()
            oldChat.pop()
            const newChat = [
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.tellUsYourExperience)},
                {id: "sendOrModify", jsx: displayExperienceDetails()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function updateContactForm(messageClient, inputValue, messageAssistant, newInput) {
        setChat(oldChat => {
            oldChat.pop()
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(`${messageClient}: ${inputValue}`)},
                {id: "assistantMessage", jsx: assistantMessage(messageAssistant)},
                {id: "subjectOption", jsx: newInput}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function displayMainOptions(): JSX.Element {
        return (
            <div className={styles.optionsContainer}>
                <a aria-label={chatbotData.knowOurServices} href={'/our-services'} className={styles.option}>
                    <p className={styles.optionTitle}>{chatbotData.knowOurServices}</p>
                </a>
                <button aria-label={chatbotData.aboutLastTrip} className={styles.option} onClick={displayTripFeedback}>
                    <p className={styles.optionTitle}>{chatbotData.aboutLastTrip}</p>
                </button>
                <button aria-label={chatbotData.faq} className={styles.option} onClick={displayFaq}>
                    <p className={styles.optionTitle}>{chatbotData.faq}</p>
                </button>
                <button aria-label={chatbotData.contactUs} className={styles.option}>
                    <p className={styles.optionTitle} onClick={renderContact}>{chatbotData.contactUs}</p>
                </button>
                <a aria-label="télécharger le guide de bien voyager" target='_blank'  href={"https://q-ksa-mad-common-euw-apim.azure-api.net/tourism/guide/GUIDE_DU_BIEN_VOYAGER.pdf"} className={styles.option}>
                  <p className={styles.optionTitle} onClick={renderContact}>Télécharger le guide de bien voyager</p>
                </a>
            </div>
        )
    }

    function displayFaq(): void {
        setChat(oldChat => {
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(chatbotData.iWantToConsultFaq)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.mostFrequentlyAskedQuestions)},
                {id: "faqOptions", jsx: renderFaqQuestions()},
            ]
            return [...oldChat, ...newChat]
        })
    }

    function renderFaqQuestions(): JSX.Element {
        const faq = chatbotData.faqQuestionsList;
        return (
            <div className={styles.optionsContainer}>
                {faq.map((item, index) => {
                    return (
                        <button aria-label="Frequent question" key={index} className={styles.option} onClick={() => {
                            displayOneFaqQuestion(item.question, item.answer)
                        }}>
                            <p className={styles.optionTitle}>{item.label}</p>
                        </button>
                    )
                })}
            </div>
        )
    }

    function displayOneFaqQuestion(question, answer) {
        setChat(oldChat => {
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(question)},
                {id: "assistantMessage", jsx: assistantMessage(answer)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.doYouResearchAnotherService)},
                {id: "mainOptions", jsx: displayMainOptions()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function renderContact() {
        setChat(oldChat => {
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(chatbotData.iWantToContactKeolis)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.whatDoYouNeed)},
                {id: "contactOptions", jsx: displayContactOptions()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function resetChat() {
        setChat([...[], ...chatInitialStatus()])
    }

    async function submitFeedbackForm() {
        const body = feedbackBody;
        const headers = {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }

        await axios.post(`${apiAddress}/demand/claim`, body, {headers: headers})
            .then(res => {
                displayEmailSended()
            }).catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            })
    }

    function displayEmailSended() {
        setChat(oldChat => {
            oldChat.pop()
            oldChat.pop()
            const newChat = [
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.thanksForYourFeedback)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.doYouResearchAnotherService)},
                {id: "contactOptions", jsx: displayMainOptions()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function displayTripFeedback() {
        setChat(oldChat => {
            oldChat.pop()
            const newChat = [
                {id: "clientMessage", jsx: clientMessage(chatbotData.shareLastTripExperience)},
                {id: "assistantMessage", jsx: assistantMessage(chatbotData.thanksForTravellingWithUs)},
                {id: "assistantMessage",jsx: assistantMessage(chatbotData.whichSubsidiaryDidYouTravelWith)},
                {id: "subsidiarySelector", jsx: displaySubsidiarySelectionInput()}
            ]
            return [...oldChat, ...newChat]
        })
    }

    function displaySubsidiarySelectionInput() {
        const handleChange = (value) => {
            let bodyCopy = feedbackBody;
            bodyCopy.subsidiaryId = value;
            setFeedbackBody({...bodyCopy})
        }

        return (
            <div className={styles.formContainer}>
                <form className={styles.emailForm} onSubmit={(e) => {
                    e.preventDefault()
                    updateContactForm(
                        "Filiale",
                        document.getElementById("subsidiaryInput").value,
                        chatbotData.whatWasTheDateOfYourTrip,
                        displayDateSelectionInput()
                    )
                }}>
                    <Autocomplete data={subsidiaries} setSubsidiary={handleChange}/>
                </form>
            </div>
        )
    }

    function displayDateSelectionInput() {
        const handleChange = (value) => {
            let bodyCopy = feedbackBody;
            bodyCopy.date = value;
            setFeedbackBody({...bodyCopy})
        }

        return (
            <DatePickerForm
                displayDestinationInput={displayDestinationInput}
                handleChange={handleChange}
                updateContactForm={updateContactForm}
                whatWasYourDestination={chatbotData.whatWasYourDestination}
            />
        )
    }

    function displayDestinationInput() {
        const handleChange = (value) => {
            let bodyCopy = feedbackBody;
            bodyCopy.destination = value;
            setFeedbackBody({...bodyCopy})
        }

        return (
            <div className={styles.formDestinationContainer}>
                <form className={styles.emailForm} onSubmit={(e) => {
                    e.preventDefault()
                    const value = document.getElementById("destination").value
                    updateContactForm(
                        "Destination",
                        value,
                        chatbotData.whatWasTheGroupName,
                        displayGroupNameInput()
                    )
                    handleChange(value)
                }}>
                    <div className={styles.inputContainer}>
                        <input id="destination" className={styles.emailInput} type="text" name="destination"
                            placeholder="Saisir la destination" required/>
                        <button aria-label="Submit button" className={styles.submitButton} type="submit">
                            <img src={arrow} className={styles.emailFormArrow} alt="Arrow icon"/>
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    function displayGroupNameInput() {
        const handleChange = (value) => {
            let bodyCopy = feedbackBody;
            bodyCopy.groupName = value;
            setFeedbackBody({...bodyCopy})
        }

        /* update input value */
        const handleInputChange = (event) => {
            const value = event.target.value;
        }

        return (
            <div className={styles.formGroupContainer}>
                <form className={styles.emailForm} onSubmit={(e) => {
                    e.preventDefault()
                    const value = document.getElementById("groupName").value
                    updateContactForm(
                        "Nom du groupe:",
                        value,
                        chatbotData.whatIsYourEmailAddress,
                        displayFeedbackEmailInput()
                    )
                    handleChange(value)
                }}>
                    <div className={styles.inputContainer}>
                        <input id="groupName" className={styles.emailInput} type="text" name="groupName"
                            placeholder="Saisir le nom du groupe" onChange={handleInputChange} required />
                        <button aria-label="Submit button" className={styles.submitButton} type="submit">
                            <img src={arrow} className={styles.emailFormArrow} alt="Arrow icon"/>
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    function displayFeedbackEmailInput() {
        const handleChange = (value) => {
            let bodyCopy = feedbackBody;
            bodyCopy.userEmail = value;
            setFeedbackBody({...bodyCopy})
        }

        return (
            <div className={styles.formEmailContainer}>
                <form className={styles.emailForm} onSubmit={(e) => {
                    e.preventDefault()
                    const value = document.getElementById("feedbackEmail").value
                    updateContactForm(
                        "Mon adresse email",
                        value,
                        chatbotData.tellUsYourExperience,
                        displayExperienceDetails()
                    )
                    handleChange(value)
                }}>
                    <div className={styles.inputContainer}>
                        <input
                            id="feedbackEmail"
                            className={styles.emailInput}
                            type="email"
                            name="feedbackEmail"
                            placeholder="Saisir une adresse mail"
                            required
                        />
                        <button aria-label="Submit button" className={styles.submitButton} type="submit">
                            <img src={arrow} className={styles.emailFormArrow} alt="Arrow icon"/>
                        </button>
                    </div>
                </form>
            </div>
        )
    }

    function displayExperienceDetails() {
        const handleChange = (value) => {
            let bodyCopy = feedbackBody;
            bodyCopy.message = value;
            setFeedbackBody({...bodyCopy})
        }

        return (
            <div className={styles.formEmailContainer}>
                <form className={styles.emailForm} onSubmit={(e) => {
                    handleChange(document.getElementById("experienceDetails").value)
                    e.preventDefault()
                    displayFeedbackFormConfirmation()
                }}>
                    <div className={styles.inputContainer}>
                        <input id="experienceDetails" className={styles.emailInput} type="text" name="experienceDetails"
                            placeholder="Raconter une expérience" required/>
                        <button aria-label="Submit button" className={styles.submitButton} type="submit">
                            <img src={arrow} className={styles.emailFormArrow} alt="Arrow icon"/>
                        </button>
                    </div>
                </form>
            </div>
        )
    }



}

export default Assistant;