// extracted by mini-css-extract-plugin
export var container = "assistant-module--container--oORxo";
export var containerHeader = "assistant-module--containerHeader--pAKTU";
export var chatIcon = "assistant-module--chatIcon--ol3VK";
export var containerHeaderLeftSide = "assistant-module--containerHeaderLeftSide--0LpQD";
export var containerHeaderLeftSideText = "assistant-module--containerHeaderLeftSideText--D50Kf";
export var close = "assistant-module--close--2jimZ";
export var resetButton = "assistant-module--resetButton--LbMLF";
export var closeIcon = "assistant-module--closeIcon--Zs1oq";
export var closeIconClient = "assistant-module--closeIconClient--kXbtW";
export var assistantContent = "assistant-module--assistantContent--jTENk";
export var assistantMessageContainer = "assistant-module--assistantMessageContainer--8VNAv";
export var clientMessageContainer = "assistant-module--clientMessageContainer--6hoE4";
export var bubbleAssistant = "assistant-module--bubbleAssistant--6SrYL";
export var bubbleClient = "assistant-module--bubbleClient--gfdDa";
export var message = "assistant-module--message--v2qRg";
export var iconContainer = "assistant-module--iconContainer--R-j9H";
export var clientIconContainer = "assistant-module--clientIconContainer--HB3Ys";
export var icon = "assistant-module--icon--rxeE5";
export var clientIcon = "assistant-module--clientIcon--gBol2";
export var optionsContainer = "assistant-module--optionsContainer--CAJ0b";
export var option = "assistant-module--option--dS6f5";
export var optionTitle = "assistant-module--optionTitle--w8iqB";
export var contactOptionsContainer = "assistant-module--contactOptionsContainer--syi-u";
export var contactSecondOptionsContainer = "assistant-module--contactSecondOptionsContainer--jaoYy";
export var contactOptions = "assistant-module--contactOptions--EkjGf";
export var contactOptionsMobile = "assistant-module--contactOptionsMobile--H-ien";
export var contactOptionButton = "assistant-module--contactOptionButton--EcHJI";
export var formContainer = "assistant-module--formContainer---AkfG";
export var formDestinationContainer = "assistant-module--formDestinationContainer--HZlGp";
export var formGroupContainer = "assistant-module--formGroupContainer---7XxU";
export var formEmailContainer = "assistant-module--formEmailContainer--8KrB1";
export var formBouttonsContainer = "assistant-module--formBouttonsContainer--e2r-5";
export var emailForm = "assistant-module--emailForm--xVowP";
export var dateForm = "assistant-module--dateForm--jVI1p";
export var inputContainer = "assistant-module--inputContainer--bJaMX";
export var dossierErrorMessage = "assistant-module--dossierErrorMessage--IJFNu";
export var emailFormArrow = "assistant-module--emailFormArrow--h+mF5";
export var datePickerContainer = "assistant-module--datePickerContainer--yCXki";
export var emailInput = "assistant-module--emailInput--1LmIb";
export var submitButton = "assistant-module--submitButton--f-+-v";
export var basicButton = "assistant-module--basicButton--CP0iJ";