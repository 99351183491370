import React, { useEffect, useState } from 'react';
import * as styles from './layout.module.scss';
import Header, { enableGoogleTagManagerBody } from "./header/header";
import Footer from "./footer/footer";
import {ChatbotFieldsFragment, FooterFieldsFragment, StickyButtonsFieldsFragment} from "../../../graphql-types";
import StickyButtons from "../sticky-buttons/sticky-buttons";
import { Helmet } from 'react-helmet';
import { pushGtmPageEvent } from '../../gtm/interaction-event';

type RenderProps = {
    data: FooterFieldsFragment,
    stickyButtonsData: StickyButtonsFieldsFragment,
    chatbotData: ChatbotFieldsFragment,
    seo?: any,
    pageTemplate: String,
}

const Layout:React.FC<RenderProps> = ({data, stickyButtonsData, chatbotData, children, seo, pageTemplate}) => {
    const [pageView, setPageView] = useState(false)
    useEffect(() => {
          enableGoogleTagManagerBody()
          if (pageView || !window) {
            return
          }
          let host = ""
          if (window.location) {
            host = window.location.host
          }
          const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
          const searchPattern = new RegExp("^" + "rksa")
          const isQualificationStage = searchPattern.test(host)
          pushGtmPageEvent(
            "page_view",
            pageTemplate,
          )
          setPageView(true)
      }, [])


    return (
        <div className={styles.container}>
            <Helmet htmlAttributes={{ lang: 'fr' }}>
                <meta charSet="utf-8" />
                <title>{seo ? seo.title : "Voyages Keolis"}</title>
                <meta name="description" content={seo ? seo.description : ""} />
            </Helmet>
            <meta name="viewport" content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi" />
            <Header />
            <main id="main">
                {children}
            </main>
            <StickyButtons data={stickyButtonsData} chatbotData={chatbotData} />
            <Footer data={data} />
        </div>
    )

}

export default Layout;