import React, {useState} from "react";
import * as styles from './autocomplete.module.scss'
import arrow from "../../../../assets/vectors/arrow.svg";
import disableArrow from "../../../../assets/vectors/disabledArrow.svg"

type ReactProps = {
    data: any,
    setSubsidiary: any,
}

const Autocomplete: React.FC<ReactProps> = ({data, setSubsidiary}) => {
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [userInput, setUserInput] = useState("");

    function onChange(e) {
        const userInputValue = e.currentTarget.value;

        const filteredSuggestionsValue = data && data.filter(
            suggestion =>
                suggestion.name.toLowerCase().indexOf(userInputValue.toLowerCase()) > -1
        );

        setActiveSuggestion(0);
        setFilteredSuggestions(filteredSuggestionsValue)
        setUserInput(e.currentTarget.value)
    }

    function onClick(e) {
        setActiveSuggestion(0);
        setFilteredSuggestions([])
        setUserInput(e.currentTarget.innerText)
    }

    function onKeyDown(e) {
        if(e.keyCode === 13) {
            setActiveSuggestion(0)
            setUserInput(filteredSuggestions[activeSuggestion])
        } else if( e.keyCode === 38) {
            if(activeSuggestion === 0) {
                return;
            }
            setActiveSuggestion(activeSuggestion - 1)
        } else if(e.keyCode === 40) {
            if(activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestion(activeSuggestion + 1)
        }
    }


    const selectedSubsidiary = data && data.filter(
      suggestion =>
          suggestion.name.toLowerCase() == userInput.toLowerCase()
    );

    return (
        <div className={styles.subsidiariesInputContainer}>
            <div className={styles.inputContainer}>
                <input
                    id="subsidiaryInput"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    className={styles.input}
                    type="text"
                    name="subsidiary"
                    placeholder="Saisir le nom de la filiale"
                    value={userInput}
                    autoComplete="off"
                    required
                />
                {
                    selectedSubsidiary.length == 0 ?
                    <div className={styles.submitButtonDisabled}>
                      <img src={disableArrow} className={styles.formArrow} alt="Arrow icon Disabled"/>
                    </div>
                    :
                    <button aria-label="Submit button" className={styles.submitButton} type="submit">
                      <img src={arrow} className={styles.formArrow} alt="Arrow icon"/>
                    </button>
                }
             
            </div>
            <ul className={styles.suggestions}>
                {filteredSuggestions.length !== data.length && filteredSuggestions && filteredSuggestions.map((suggestion) => {
                    return (
                        <li className={styles.suggestion} key={suggestion.name} onClick={(e) => {
                            onClick(e)
                            setSubsidiary(suggestion.id)
                        }}>
                            {suggestion.name}
                        </li>
                    )
                })}
            </ul>

        </div>
    )
}

export default Autocomplete
